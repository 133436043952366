var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-hidden-mobile is-3-tablet is-2-desktop"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"content"},[_c('b-button',{staticClass:"button is-success is-fullwidth is-outlined is-rounded is-uppercase",attrs:{"tag":"router-link","to":{ name: 'NewBooking' },"icon-left":"plus-circle-outline"}},[_vm._v(" "+_vm._s(_vm.$t('quickView.newOrder'))+" ")])],1),_c('b-menu',[(_vm.unconfirmedBookings.length > 0)?_c('b-menu-list',{attrs:{"icon":"chevron-right","label":_vm.$t('quickView.unconfirmed')}},_vm._l((_vm.unconfirmedBookings),function(ub,index){return _c('b-menu-item',{key:index,staticClass:"unconfirmed is-size-7",attrs:{"icon":"minus","label":ub.name,"tag":"router-link","to":{
                        name: 'Overview',
                        query: { list: 'pending' }
                    }}})}),1):_vm._e(),_c('b-menu-list',{attrs:{"icon":"chevron-right","label":_vm.$t('quickView.confirmed')}},[_c('b-menu-item',{staticClass:"new is-size-7",class:_vm.$route.query.view == null
                            ? 'has-text-weight-semibold	'
                            : '',attrs:{"icon":"minus","label":"Normal vy","tag":"router-link","to":{ name: 'Overview' }}}),_c('b-menu-item',{staticClass:"new is-size-7",class:_vm.$route.query.view == 'compact'
                            ? 'has-text-weight-semibold	'
                            : '',attrs:{"icon":"minus","label":_vm.$t('quickView.compactView'),"tag":"router-link","to":{ name: 'Overview', query: { view: 'compact' } }}}),_c('b-menu-item',{staticClass:"new is-size-7",class:_vm.$route.query.view == 'minimal'
                            ? 'has-text-weight-semibold	'
                            : '',attrs:{"icon":"minus","label":_vm.$t('quickView.minimalView'),"tag":"router-link","to":{ name: 'Overview', query: { view: 'minimal' } }}}),_c('b-menu-item',{staticClass:"new is-size-7",class:_vm.$route.query.view == 'calendar'
                            ? 'has-text-weight-semibold	'
                            : '',attrs:{"icon":"minus","label":_vm.$t('quickView.calendarView'),"tag":"router-link","to":{ name: 'Overview', query: { view: 'calendar' } }}})],1),(_vm.showHistory)?[_c('b-menu-list',{attrs:{"icon":"chevron-right","label":_vm.$t('quickView.history')}},[_vm._l((_vm.months),function(m,index){return [(index <= _vm.currentMonth)?_c('b-menu-item',{key:index,staticClass:"history is-size-7",attrs:{"icon":"calendar-range","label":m,"tag":"router-link","to":{
                                name: 'History',
                                query: { month: m.toLowerCase() }
                            }}}):_vm._e()]})],2)]:_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }