<template>
    <div class="section pt-0">
        <FullCalendar :options="calendarOptions" />
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'

export default {
    name: 'CalendarView',
    components: { FullCalendar },
    props: { view: String, events: Array },
    data() {
        return {
            calendarOptions: {
                plugins: [listPlugin, timeGridPlugin],
                initialView: this.view,
                nowIndicator: true,
                slotMinTime: '08:00:00',
                slotMaxTime: '20:00:00',
                events: this.events,
                weekends: false,
                firstDay: 1,
                locale: 'sv',
                height: 'auto',
                aspectRatio: 2,
                eventClick: this.viewBooking,
                customButtons: {
                    weekend: {
                        text: this.$t('calendar.toggleWeekend'),
                        click: this.toggleWeekends
                    }
                },
                headerToolbar: {
                    right: 'weekend today prev,next'
                }
            }
        }
    },
    methods: {
        viewBooking(arg) {
            this.$router.push({
                name: 'EditBooking',
                params: {
                    uuid: arg.event.id
                }
            })
        },
        toggleWeekends: function() {
            this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
        }
    }
}
</script>
