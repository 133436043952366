<template>
    <article
        class="bookingcard"
        :class="{ 'has-background-warning': booking.cancelledAt }"
    >
        <p class="is-size-7 has-text-grey has-text-weight-semibold time">
            {{ booking.confirmedDate | formatDate('HH:mm') }}
            <span
                v-if="booking.status.event === 'cancel_request'"
                class="is-pulled-right mr-2"
            >
                {{
                    $t('booking.card.cancelRequested', {
                        cancelledAt: cancelRequested
                    })
                }}
            </span>
        </p>
        <b-collapse
            :style="{
                'border-left-style': 'solid',
                'border-left-width': 'medium',
                'border-color': booking.product.rgbColor
                    ? booking.product.rgbColor
                    : 'royalblue'
            }"
            class="card"
            :open="false"
            :aria-id="booking.fullAddress"
        >
            <div
                slot="trigger"
                slot-scope="props"
                class="columns is-vcentered is-mobile"
                role="button"
                :aria-controls="booking.fullAddress"
            >
                <div class="column is-10">
                    <h2 class="title is-5">
                        <b-tooltip
                            v-if="booking.status.event === 'cancel_request'"
                            type="is-warning"
                            :label="
                                $t('booking.card.cancelRequested', {
                                    cancelledAt: cancelRequested
                                })
                            "
                            position="is-right"
                        >
                            <b-icon
                                icon="alert-circle-outline"
                                type="is-warning"
                            />
                        </b-tooltip>
                        {{ booking.fullAddress }}
                    </h2>
                    <p
                        class="subtitle is-6 has-text-weight-light has-text-grey-light"
                    >
                        {{ showBookingFullName }}
                    </p>
                    <span class="is-small">
                        {{ showProductAndEndCustomer }}
                        <span v-if="booking.doorCode">
                            ({{ $t('booking.card.doorCode') }}
                            {{ booking.doorCode }})
                        </span>
                    </span>
                </div>
                <div
                    class="column is-1 booking has-text-centered is-paddingless"
                >
                    <a
                        v-if="timeSinceFinished <= 15"
                        :href="
                            'https://www.google.com/maps/dir/?api=1&destination=' +
                                encodeURIComponent(booking.fullAddress)
                        "
                        target="_blank"
                    >
                        <b-icon class="is-hidden-desktop" icon="navigation" />
                        <b-icon icon="google-maps" class="is-hidden-mobile" />
                    </a>
                    <b-tooltip
                        v-else-if="isSeller"
                        :label="$t('booking.card.uploadMedia')"
                        type="is-light"
                        position="is-left"
                    >
                        <router-link
                            :to="{
                                path: '/booking/' + booking.uuid + '/upload'
                            }"
                        >
                            <b-icon icon="image-plus" />
                        </router-link>
                    </b-tooltip>
                </div>
                <div class="column is-1 has-text-centered is-paddingless">
                    <b-icon :icon="props.open ? 'menu-up' : 'menu-down'" />
                </div>
            </div>
            <div class="columns">
                <div class="column is-12 footer-column">
                    <footer class="card-footer">
                        <a
                            v-if="booking.phone"
                            :href="'tel://' + booking.phone"
                            class="card-footer-item"
                            :alt="'Ring ' + booking.customer.fullName"
                        >
                            <b-icon type="is-success" icon="phone" />
                        </a>
                        <span v-else class="card-footer-item">
                            <b-icon type="is-grey" icon="phone" />
                        </span>
                        <template v-if="booking['@type'] === 'Booking'">
                            <router-link
                                v-if="isSeller"
                                :to="{
                                    name: 'EditBooking',
                                    params: { uuid: booking.uuid }
                                }"
                                class="card-footer-item"
                            >
                                <b-icon icon="square-edit-outline" />
                            </router-link>
                            <router-link
                                v-else
                                :to="'/booking/' + booking.uuid"
                                class="card-footer-item"
                            >
                                <b-icon icon="eye-outline" />
                            </router-link>
                        </template>
                        <span v-else class="card-footer-item">
                            <b-icon type="is-dark" icon="eye-outline" />
                        </span>
                        <span
                            v-if="
                                !isSeller &&
                                    booking.status.event === 'cancel_request'
                            "
                            class="card-footer-item"
                        >
                            <b-icon
                                icon="alert-circle-outline"
                                type="is-warning"
                                class="mr-4"
                            />
                        </span>
                        <a
                            v-else
                            class="card-footer-item"
                            @click="$parent.deleteBooking(booking)"
                        >
                            <b-icon
                                v-if="allowedToDelete"
                                type="is-danger"
                                icon="delete"
                            />
                            <b-icon v-else icon="delete-off" type="is-danger" />
                        </a>
                    </footer>
                </div>
            </div>
        </b-collapse>
    </article>
</template>

<script>
import format from '@/_lib/format'
import {
    parseISO,
    differenceInHours,
    differenceInSeconds,
    addSeconds
} from 'date-fns'

export default {
    name: 'BookingCard',
    props: { booking: Object },
    data() {
        return {
            timeSinceFinished: null,
            now: Date.now()
        }
    },
    computed: {
        isSeller() {
            return this.$store.getters['security/hasRole']('ROLE_SELLER')
        },
        bookingPhone() {
            return this.isSeller
                ? this.booking.customer.phone
                : this.booking.owner.phone
        },
        cancelRequested() {
            return this.booking.cancelledAt
                ? format(this.booking.cancelledAt, 'd MMM HH:mm')
                : this.$t('booking.card.cancelRequestedNoDate')
        },
        showProductAndEndCustomer() {
            return this.$t('booking.card.productAndEndCustomer', {
                product: this.booking.productName,
                endCustomer: this.booking.name
            })
        },
        showBookingFullName() {
            return this.isSeller
                ? this.$t('booking.card.with.seller', {
                      fullName: this.booking.customer.fullName,
                      usergroup: this.booking.customer.usergroupName
                  })
                : this.$t('booking.card.with.customer', {
                      fullName: this.booking.owner.fullName,
                      usergroup: this.booking.owner.usergroupName
                  })
        },
        allowedToDelete() {
            let duration = differenceInHours(
                parseISO(this.booking.confirmedDate),
                new Date()
            )

            return this.isSeller || duration > 24
        }
    },
    methods: {
        getTimeSinceFinished() {
            this.timeSinceFinished = differenceInSeconds(
                new Date(),
                addSeconds(
                    parseISO(this.booking.confirmedDate),
                    this.booking.product.length
                )
            )
        }
    },
    created() {
        if (this.booking['@type'] === 'Booking') {
            this.getTimeSinceFinished()
            setInterval(this.getTimeSinceFinished, 1000)
        }
    },
    destroyed() {
        if (this.booking['@type'] === 'Booking') {
            clearInterval(this.getTimeSinceFinished)
        }
    }
}
</script>
