<template>
    <div class="columns">
        <QuickView :unconfirmed-bookings="pendingBookings" />
        <div class="column is-paddingless is-10-desktop is-9-tablet">
            <div class="section is-hidden-mobile">
                <nav class="level">
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="heading">
                                {{ infoPanelPending }}
                            </p>
                            <p class="title">
                                {{ pendingBookings.length }}
                            </p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="heading">
                                {{ $t('overview.infoPanel.confirmed') }}
                            </p>
                            <p class="title">
                                {{ amountOfConfirmedBookings }}
                            </p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="heading">
                                {{ $t('overview.infoPanel.done') }}
                            </p>
                            <p class="title">
                                0
                            </p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="heading">
                                {{ $t('overview.infoPanel.denied') }}
                            </p>
                            <p class="title">
                                0
                            </p>
                        </div>
                    </div>
                </nav>
            </div>

            <div
                id="dateScroll"
                class="container is-hidden-tablet is-hidden-desktop"
            >
                <b-tabs
                    expanded
                    position="is-centered"
                    class="block"
                    @change="obj => (currentDateIndex = obj)"
                >
                    <template
                        v-for="(currdate, name, index) in confirmedBookings"
                    >
                        <b-tab-item
                            :key="index"
                            :label="name"
                            :visible="showTab(index)"
                        >
                            <MobileViewList :date="name" :bookings="currdate" />
                        </b-tab-item>
                    </template>
                </b-tabs>
            </div>

            <section v-if="error" class="content">
                <div class="container">
                    <article class="message is-danger">
                        <div class="message-body">
                            {{ error }}
                        </div>
                    </article>
                    <p v-if="bookingDeleted">
                        Success! Booking deleted!
                    </p>
                </div>
            </section>

            <div
                v-if="!isLoading && !hasBookings && $route.query.view == null"
                class="section"
            >
                <b-message type="is-info">
                    Hallå! Det är lite tomt här... Använd "Ny Order"-knappen här
                    till vänster eller
                    <strong>
                        <router-link to="/booking">klicka här</router-link>
                    </strong>
                    för att boka.
                </b-message>
            </div>

            <b-datepicker
                v-if="$route.query.view == 'calendar'"
                v-model="date"
                inline
                class="is-hidden-desktop"
                :events="cevents"
                indicators="dots"
            />

            <template
                v-if="
                    ($route.query.view == 'pending' ||
                        pendingBookings.length > 0) &&
                        $route.query.view !== 'calendar'
                "
            >
                <div class="section pt-0">
                    <p v-if="pendingBookings.length > 0" class="title">
                        {{ inquires }}
                    </p>
                    <CompactPending :bookings="pendingBookings" />
                    <hr />
                </div>
            </template>

            <CalendarView
                v-if="$route.query.view == 'calendar'"
                key="timeGridWeek"
                view="timeGridWeek"
                class="is-hidden-mobile"
                :events="calendarEvents"
            />

            <CalendarView
                v-if="$route.query.view == 'compact'"
                key="listWeek"
                view="listWeek"
                class="is-hidden-mobile"
                :events="calendarEvents"
            />

            <MinimalView
                v-if="$route.query.view == 'minimal'"
                :bookings="confirmedBookings"
            />

            <div
                v-if="!$route.query.view"
                id="bookingCards"
                class="section is-hidden-mobile"
            >
                <b-loading
                    v-if="hasBookings"
                    :active.sync="isLoading"
                ></b-loading>
                <BookingSkeleton
                    v-if="!hasBookings && isLoading"
                    :skeletons="3"
                />
                <template
                    v-for="(bookingDate, name, index) in confirmedBookings"
                >
                    <div :key="index" class="content">
                        <h2
                            class="title is-size-4-mobile has-text-grey has-text-centered"
                        >
                            {{
                                name
                                    | formatDate(
                                        'EEEE do MMMM yyyy',
                                        'parse',
                                        'yyyy-MM-dd'
                                    )
                            }}
                        </h2>
                    </div>

                    <BookingCard
                        v-for="booking in bookingDate"
                        :key="booking.uuid"
                        :loading="isLoading"
                        :booking="booking"
                        :created="timeCreated"
                    />
                    <hr :key="'hr' + index" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import QuickView from '../components/QuickView'
import BookingCard from '../components/overview/BookingCard'
import CalendarView from '../components/overview/CalendarView'
import MinimalView from '../components/overview/MinimalView'
import MobileViewList from '../components/overview/MobileViewList'
import CompactPending from '../components/overview/CompactPending'
import BookingSkeleton from '../components/overview/BookingSkeleton'
import format from '@/_lib/format'
import { parseJSON, addSeconds, differenceInHours } from 'date-fns'

const thisMonth = new Date().getMonth()
export default {
    name: 'Overview',
    components: {
        QuickView,
        BookingCard,
        CalendarView,
        MinimalView,
        MobileViewList,
        CompactPending,
        BookingSkeleton
    },
    data() {
        return {
            currentDateIndex: 1,
            timeCreated: Date.now(),
            bookingDeleted: null,
            date: new Date(2017, thisMonth, 1),
            cevents: [
                new Date(2017, thisMonth, 2),
                new Date(2017, thisMonth, 6),
                {
                    date: new Date(2017, thisMonth, 6),
                    type: 'pinkshit'
                },
                {
                    date: new Date(2017, thisMonth, 8),
                    type: 'is-danger'
                },
                {
                    date: new Date(2017, thisMonth, 10),
                    type: 'is-success'
                },
                {
                    date: new Date(2017, thisMonth, 10),
                    type: 'is-link'
                },
                {
                    date: new Date(2017, thisMonth, 12),
                    type: 'is-warning'
                },
                {
                    date: new Date(2017, thisMonth, 16),
                    type: 'is-danger'
                },
                new Date(2017, thisMonth, 20),
                {
                    date: new Date(2017, thisMonth, 29),
                    type: 'is-success'
                },
                {
                    date: new Date(2017, thisMonth, 29),
                    type: 'is-warning'
                },
                {
                    date: new Date(2017, thisMonth, 29),
                    type: 'is-info'
                }
            ],
            bars: false
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters['security/isAuthenticated']
        },
        isCustomer() {
            return this.$store.getters['security/hasRole']('ROLE_CUSTOMER')
        },
        isLoading() {
            return this.$store.getters['booking/isLoading']
        },
        hasError() {
            return this.$store.getters['booking/hasError']
        },
        error() {
            return this.$store.getters['booking/error']
        },
        hasBookings() {
            return this.$store.getters['booking/hasBookings']
        },
        bookings() {
            return this.$store.getters['booking/bookings']
        },
        events() {
            return this.$store.getters['event/events']
        },
        infoPanelPending() {
            return this.isSeller
                ? this.$t('overview.infoPanel.pending.seller')
                : this.$t('overview.infoPanel.pending.customer')
        },
        inquires() {
            return this.isSeller
                ? this.$t('overview.inquiries.seller')
                : this.$t('overview.inquiries.customer')
        },
        currentDateObj() {
            let key = Object.keys(this.confirmedBookings)
            return this.confirmedBookings[key[this.currentDateIndex]]
        },
        confirmedBookings() {
            let events = this.events.reduce((base, event) => {
                // let date = format(parseJSON(event.beginAt), 'yyyy-MM-dd')

                base.push({
                    status: {
                        event: event.status.event
                    },
                    ...event,
                    product: { rgbColor: '#b8bac2' },
                    phone: '',
                    customer: { fullName: 'me', usergroupName: 'calendar' },
                    name: event.status.event,

                    fullAddress: event.title,
                    productName: event.note,
                    confirmedDate: event.beginAt
                })
                /*
                if (!cb[date]) {
                    cb[date] = [convertedEvent]
                } else {
                    cb[date].push(convertedEvent)
                } */

                return base
            }, [])

            let temp = Object.values(this.bookings)
                .concat(events)
                .sort(function(a, b) {
                    var nameA = a.confirmedDate
                    var nameB = b.confirmedDate
                    if (nameA < nameB) {
                        return -1
                    }
                    if (nameA > nameB) {
                        return 1
                    }
                    return 0
                })
            return temp.reduce((base, booking) => {
                if (!booking.status) {
                    return base
                }
                if (
                    booking.status.event === 'confirmed' ||
                    booking.status.event === 'cancel_request' ||
                    booking.status.event === 'reservation'
                ) {
                    let date = format(booking.confirmedDate, 'yyyy-MM-dd')

                    base[date] = base[date]
                        ? base[date].concat(booking)
                        : [booking]
                }
                return base
            }, {})
        },
        amountOfConfirmedBookings() {
            let amount = 0
            Object.values(this.confirmedBookings).forEach(date => {
                amount = amount + date.length
            })
            return amount
        },
        pendingBookings() {
            const pb = this.bookings.reduce((base, booking) => {
                if (booking.status.event === 'new') {
                    base.push(booking)
                }

                return base
            }, [])

            return pb
        },
        calendarEvents() {
            const ce = this.bookings.reduce((base, booking) => {
                let event = {}

                if (booking.confirmedDate) {
                    let end = addSeconds(
                        parseJSON(booking.confirmedDate),
                        booking.product.length
                    )
                    // moment(booking.confirmedDate).add(booking.product.length, 'seconds').format()
                    event = {
                        id: booking.uuid,
                        start: parseJSON(booking.confirmedDate),
                        end: end,
                        title: booking.product.name,
                        color: booking.product.rgbColor || null,
                        urls: '/bookings/' + booking.uuid
                    }
                } else {
                    /* if (booking.reservationFrom === booking.reservationTo) {
                        event = {
                            start: booking.reservationFrom,
                            end: moment(booking.reservationTo).add(booking.product.length, 'seconds').format(),
                            title: booking.product.name
                        }
                    } else {
                        event = {
                            start: booking.reservationFrom,
                            end: booking.reservationTo,
                            title: booking.product.name
                        }
                    } */
                }
                base.push(event)
                return base
            }, [])

            return ce
        }
    },
    methods: {
        showTab(index) {
            if (
                this.currentDateIndex - 1 === index ||
                this.currentDateIndex === index ||
                this.currentDateIndex + 1 === index
            ) {
                return true
            }
            return false
        },
        borderStyle(color) {
            let hex = color || '#00C7A9'
            let style =
                'border-left-style: solid; border-left-color: ' +
                hex +
                '; border-left-width:thick;'
            return style
        },
        printDayHeader(index) {
            let ldo =
                index !== 0
                    ? new Date(this.bookings[index - 1]['confirmedDate'])
                    : new Date()
            let cdo = new Date(this.bookings[index]['confirmedDate'])
            let lastDate = ldo.getFullYear() + ldo.getMonth() + ldo.getDate()
            let currentDate = cdo.getFullYear() + cdo.getMonth() + cdo.getDate()
            // console.log('last: %s, curr: %s', ldo, cdo)
            return lastDate !== currentDate
            // return true
        },
        deleteBooking(booking) {
            // let confirmed = moment(booking.confirmedDate)
            // let duration = moment.duration(confirmed.diff(new Date()))

            let duration = differenceInHours(
                new Date(),
                parseJSON(booking.confirmedDate)
            )

            let title = this.$t('dialogs.delete.title')

            let message = this.$t('dialogs.delete.message', {
                object: booking.fullAddress
            })

            let confirmButton = this.$t('dialogs.delete.confirmButton')

            if (!this.isSeller && duration < 24) {
                title = this.$t('booking.dialogs.delete.title')
                message = this.$t('booking.dialogs.delete.requestMessage', {
                    object: booking.fullAddress,
                    hoursUntil: Math.trunc(duration),
                    owner: booking.owner.firstName
                })
                confirmButton = this.$t('booking.dialogs.delete.confirmButton')
            }

            this.$buefy.dialog.confirm({
                title: title,
                message: message,
                confirmText: confirmButton,
                cancelText: this.$t('dialogs.delete.cancelButton'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.$store.dispatch('booking/delete', booking)
            })
            // this.$store.dispatch('booking/delete', booking)
            // this.bookingDeleted = !this.hasError
        },
        loadBookings() {
            this.$store.dispatch('booking/listBookings')
        },
        async confirmBooking(booking) {
            if (booking.confirmedDate) {
                let confirm = {
                    '@id': booking['@id'],
                    confirmedDate: booking.confirmedDate,
                    state: 'confirmed'
                }
                await this.$store.dispatch('booking/update', confirm)
                this.bookings.splice(this.bookings.indexOf(booking), 1)
                this.$store.dispatch('booking/listBookings')
            } else {
                alert('Missing date')
            }
        }
    },
    created() {
        this.$store.dispatch('booking/listBookings')
        this.$store.dispatch('event/list', {
            start: new Date().toLocaleDateString()
        })
    }
}
</script>
