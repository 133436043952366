<template>
    <section class="bookingSkeleton">
        <div class="content centered">
            <b-skeleton active height="25px"></b-skeleton>
        </div>
        <article v-for="s in skeletons" :key="s" class="media">
            <div class="media-content">
                <nav class="level is-mobile">
                    <div class="level-left">
                        <a class="level-item">
                            <span class="icon is-small">
                                <b-skeleton></b-skeleton>
                            </span>
                        </a>
                        <a class="level-item">
                            <span class="icon is-small">
                                <b-skeleton></b-skeleton>
                            </span>
                        </a>
                    </div>
                </nav>
                <div class="content">
                    <p>
                        <b-skeleton active width="25%"></b-skeleton>
                        <b-skeleton height="80px"></b-skeleton>
                    </p>
                </div>
            </div>
        </article>
    </section>
</template>

<script>
export default {
    name: 'BookingSkeleton',
    props: { skeletons: Number }
}
</script>

<style scoped>
.bookingSkeleton {
    margin-top: 50px;
}
.centered {
    margin: 0 auto;
    width: 25%;
}
</style>
