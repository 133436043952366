<template>
    <div class="column is-hidden-mobile is-3-tablet is-2-desktop">
        <div class="section">
            <div class="content">
                <b-button
                    tag="router-link"
                    :to="{ name: 'NewBooking' }"
                    class="button is-success is-fullwidth is-outlined is-rounded is-uppercase"
                    icon-left="plus-circle-outline"
                >
                    {{ $t('quickView.newOrder') }}
                </b-button>
            </div>

            <b-menu>
                <b-menu-list
                    v-if="unconfirmedBookings.length > 0"
                    icon="chevron-right"
                    :label="$t('quickView.unconfirmed')"
                >
                    <b-menu-item
                        v-for="(ub, index) in unconfirmedBookings"
                        :key="index"
                        icon="minus"
                        :label="ub.name"
                        class="unconfirmed is-size-7"
                        tag="router-link"
                        :to="{
                            name: 'Overview',
                            query: { list: 'pending' }
                        }"
                    />
                </b-menu-list>

                <b-menu-list
                    icon="chevron-right"
                    :label="$t('quickView.confirmed')"
                >
                    <b-menu-item
                        icon="minus"
                        label="Normal vy"
                        tag="router-link"
                        :to="{ name: 'Overview' }"
                        class="new is-size-7"
                        :class="
                            $route.query.view == null
                                ? 'has-text-weight-semibold	'
                                : ''
                        "
                    />
                    <b-menu-item
                        icon="minus"
                        :label="$t('quickView.compactView')"
                        tag="router-link"
                        :to="{ name: 'Overview', query: { view: 'compact' } }"
                        class="new is-size-7"
                        :class="
                            $route.query.view == 'compact'
                                ? 'has-text-weight-semibold	'
                                : ''
                        "
                    />
                    <b-menu-item
                        icon="minus"
                        :label="$t('quickView.minimalView')"
                        tag="router-link"
                        :to="{ name: 'Overview', query: { view: 'minimal' } }"
                        class="new is-size-7"
                        :class="
                            $route.query.view == 'minimal'
                                ? 'has-text-weight-semibold	'
                                : ''
                        "
                    />
                    <b-menu-item
                        icon="minus"
                        :label="$t('quickView.calendarView')"
                        tag="router-link"
                        :to="{ name: 'Overview', query: { view: 'calendar' } }"
                        class="new is-size-7"
                        :class="
                            $route.query.view == 'calendar'
                                ? 'has-text-weight-semibold	'
                                : ''
                        "
                    />
                </b-menu-list>

                <template v-if="showHistory">
                    <b-menu-list
                        icon="chevron-right"
                        :label="$t('quickView.history')"
                    >
                        <template v-for="(m, index) in months">
                            <b-menu-item
                                v-if="index <= currentMonth"
                                :key="index"
                                icon="calendar-range"
                                :label="m"
                                class="history is-size-7"
                                tag="router-link"
                                :to="{
                                    name: 'History',
                                    query: { month: m.toLowerCase() }
                                }"
                            />
                        </template>
                    </b-menu-list>
                </template>
            </b-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuickView',
    props: {
        unconfirmedBookings: Array,
        confirmedBookings: Array
    },
    data() {
        return {
            showHistory: false,
            months: {
                0: this.$t('general.months.january'),
                1: this.$t('general.months.february'),
                2: this.$t('general.months.march'),
                3: this.$t('general.months.april'),
                4: this.$t('general.months.may'),
                5: this.$t('general.months.june'),
                6: this.$t('general.months.july'),
                7: this.$t('general.months.august'),
                8: this.$t('general.months.september'),
                9: this.$t('general.months.october'),
                10: this.$t('general.months.november'),
                11: this.$t('general.months.december')
            },
            currentMonth: new Date().getMonth()
        }
    }
}
</script>
