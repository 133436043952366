<template>
    <section class="section pt-0">
        <table class="table is-narrow is-fullwidth">
            <thead>
                <tr>
                    <th></th>
                    <th>Tid</th>
                    <th>Address</th>

                    <th>Kund</th>
                    <th>Produkt</th>
                </tr>
            </thead>
            <tbody v-for="(dates, date, index) in bookings" :key="index">
                <tr>
                    <td
                        colspan="5"
                        class="has-text-centered has-background-light has-text-weight-semibold is-size-5"
                    >
                        {{
                            date
                                | formatDate(
                                    'EEEE do MMMM',
                                    'parse',
                                    'yyyy-MM-dd'
                                )
                        }}
                    </td>
                </tr>
                <tr v-for="(booking, bidx) in dates" :key="bidx">
                    <td>
                        <a
                            :href="
                                'https://www.google.com/maps/dir/?api=1&destination=' +
                                    encodeURIComponent(booking.fullAddress)
                            "
                            target="_blank"
                        >
                            <b-icon icon="navigation" />
                        </a>
                    </td>

                    <td>{{ booking.confirmedDate | formatDate('HH:mm') }}</td>
                    <td>
                        <router-link
                            :to="{
                                name: 'EditBooking',
                                params: {
                                    uuid: booking.uuid
                                }
                            }"
                            tag="a"
                        >
                            {{ booking.fullAddress }}
                        </router-link>
                    </td>
                    <td>{{ booking.customer.fullName }}</td>
                    <td>{{ booking.product.name }}</td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
export default {
    name: 'MinimalView',
    props: { bookings: Object }
}
</script>
