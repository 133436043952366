var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"bookingcard",class:{ 'has-background-warning': _vm.booking.cancelledAt }},[_c('p',{staticClass:"is-size-7 has-text-grey has-text-weight-semibold time"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.booking.confirmedDate,'HH:mm'))+" "),(_vm.booking.status.event === 'cancel_request')?_c('span',{staticClass:"is-pulled-right mr-2"},[_vm._v(" "+_vm._s(_vm.$t('booking.card.cancelRequested', { cancelledAt: _vm.cancelRequested }))+" ")]):_vm._e()]),_c('b-collapse',{staticClass:"card",style:({
            'border-left-style': 'solid',
            'border-left-width': 'medium',
            'border-color': _vm.booking.product.rgbColor
                ? _vm.booking.product.rgbColor
                : 'royalblue'
        }),attrs:{"open":false,"aria-id":_vm.booking.fullAddress},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return _c('div',{staticClass:"columns is-vcentered is-mobile",attrs:{"role":"button","aria-controls":_vm.booking.fullAddress}},[_c('div',{staticClass:"column is-10"},[_c('h2',{staticClass:"title is-5"},[(_vm.booking.status.event === 'cancel_request')?_c('b-tooltip',{attrs:{"type":"is-warning","label":_vm.$t('booking.card.cancelRequested', {
                                cancelledAt: _vm.cancelRequested
                            }),"position":"is-right"}},[_c('b-icon',{attrs:{"icon":"alert-circle-outline","type":"is-warning"}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.booking.fullAddress)+" ")],1),_c('p',{staticClass:"subtitle is-6 has-text-weight-light has-text-grey-light"},[_vm._v(" "+_vm._s(_vm.showBookingFullName)+" ")]),_c('span',{staticClass:"is-small"},[_vm._v(" "+_vm._s(_vm.showProductAndEndCustomer)+" "),(_vm.booking.doorCode)?_c('span',[_vm._v(" ("+_vm._s(_vm.$t('booking.card.doorCode'))+" "+_vm._s(_vm.booking.doorCode)+") ")]):_vm._e()])]),_c('div',{staticClass:"column is-1 booking has-text-centered is-paddingless"},[(_vm.timeSinceFinished <= 15)?_c('a',{attrs:{"href":'https://www.google.com/maps/dir/?api=1&destination=' +
                            encodeURIComponent(_vm.booking.fullAddress),"target":"_blank"}},[_c('b-icon',{staticClass:"is-hidden-desktop",attrs:{"icon":"navigation"}}),_c('b-icon',{staticClass:"is-hidden-mobile",attrs:{"icon":"google-maps"}})],1):(_vm.isSeller)?_c('b-tooltip',{attrs:{"label":_vm.$t('booking.card.uploadMedia'),"type":"is-light","position":"is-left"}},[_c('router-link',{attrs:{"to":{
                            path: '/booking/' + _vm.booking.uuid + '/upload'
                        }}},[_c('b-icon',{attrs:{"icon":"image-plus"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"column is-1 has-text-centered is-paddingless"},[_c('b-icon',{attrs:{"icon":props.open ? 'menu-up' : 'menu-down'}})],1)])}}])},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12 footer-column"},[_c('footer',{staticClass:"card-footer"},[(_vm.booking.phone)?_c('a',{staticClass:"card-footer-item",attrs:{"href":'tel://' + _vm.booking.phone,"alt":'Ring ' + _vm.booking.customer.fullName}},[_c('b-icon',{attrs:{"type":"is-success","icon":"phone"}})],1):_c('span',{staticClass:"card-footer-item"},[_c('b-icon',{attrs:{"type":"is-grey","icon":"phone"}})],1),(_vm.booking['@type'] === 'Booking')?[(_vm.isSeller)?_c('router-link',{staticClass:"card-footer-item",attrs:{"to":{
                                name: 'EditBooking',
                                params: { uuid: _vm.booking.uuid }
                            }}},[_c('b-icon',{attrs:{"icon":"square-edit-outline"}})],1):_c('router-link',{staticClass:"card-footer-item",attrs:{"to":'/booking/' + _vm.booking.uuid}},[_c('b-icon',{attrs:{"icon":"eye-outline"}})],1)]:_c('span',{staticClass:"card-footer-item"},[_c('b-icon',{attrs:{"type":"is-dark","icon":"eye-outline"}})],1),(
                            !_vm.isSeller &&
                                _vm.booking.status.event === 'cancel_request'
                        )?_c('span',{staticClass:"card-footer-item"},[_c('b-icon',{staticClass:"mr-4",attrs:{"icon":"alert-circle-outline","type":"is-warning"}})],1):_c('a',{staticClass:"card-footer-item",on:{"click":function($event){return _vm.$parent.deleteBooking(_vm.booking)}}},[(_vm.allowedToDelete)?_c('b-icon',{attrs:{"type":"is-danger","icon":"delete"}}):_c('b-icon',{attrs:{"icon":"delete-off","type":"is-danger"}})],1)],2)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }