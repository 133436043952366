<template>
    <table class="table is-striped is-narrow is-hoverable is-fullwidth">
        <tbody v-for="(b, index) in bookings" :key="index">
            <tr
                :key="b.uuid"
                class="pending"
                :style="'border-color: #' + b.product.rgbColor + ' !important;'"
            >
                <td>
                    <p class="title is-6">
                        {{ b.product.name }}
                    </p>
                    <p class="subtitle is-7">
                        {{ fullName(b) }}
                    </p>
                </td>

                <td>
                    <a
                        :href="
                            'https://www.google.com/maps/dir/?api=1&destination=' +
                                encodeURIComponent(b.fullAddress)
                        "
                        target="_blank"
                    >
                        {{ b.fullAddress }}
                    </a>
                </td>

                <td
                    v-if="isSameDay(b.reservationFrom, b.reservationTo)"
                    colspan="2"
                    class="has-text-centered"
                >
                    {{ b.reservationFrom | formatDate('d/M HH:mm') }}
                    -
                    {{ b.reservationTo | formatDate('HH:mm') }}
                </td>
                <template v-else>
                    <td colspan="2" class="has-text-centered">
                        <b-tooltip :label="reservationFrom(b)" type="is-light">
                            {{ b.reservationFrom | formatDate('d/M HH:mm') }}
                        </b-tooltip>
                        -
                        <b-tooltip :label="reservationTo(b)" type="is-light">
                            {{ b.reservationTo | formatDate('d/M HH:mm') }}
                        </b-tooltip>
                    </td>
                </template>
                <td class="has-text-centered">
                    <a class="is-uppercase" @click="changeDate(b)">
                        <span class="tag is-warning is-rounded">
                            <span>{{
                                $t('overview.pendingBookings.buttons.change')
                            }}</span>
                            <b-icon icon="update" />
                        </span>
                    </a>
                </td>

                <td class="has-text-right">
                    <a
                        v-if="isSeller"
                        class="has-text-success"
                        @click="changeDate(b)"
                    >
                        <b-icon icon="check-bold" />
                    </a>

                    <a
                        class="has-text-danger"
                        @click="$parent.deleteBooking(b)"
                    >
                        <b-icon icon="delete" />
                    </a>
                </td>
            </tr>
            <tr
                v-if="isSeller && showChangeDate == b.uuid"
                style="height: 200px;"
            >
                <td colspan="6">
                    <b-field grouped position="is-centered">
                        <b-input v-model="b.confirmedDate" type="text" />
                        <p class="control">
                            <b-button
                                type="is-success"
                                class="is-outlined"
                                :disabled="!b.confirmedDate"
                                @click="$parent.confirmBooking(b)"
                            >
                                {{
                                    $t(
                                        'overview.pendingBookings.buttons.confirmDateTime'
                                    )
                                }}
                            </b-button>
                        </p>
                    </b-field>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import format from '@/_lib/format'

export default {
    name: 'CompactPending',
    props: { bookings: Array },
    data() {
        return {
            confirmDate: null,
            pending: this.bookings,
            showChangeDate: null
        }
    },
    computed: {
        isSeller() {
            return this.$store.getters['security/hasRole']('ROLE_SELLER')
        }
    },
    methods: {
        fullName(b) {
            // console.log(b)
            return this.isSeller ? b.customer.fullName : b.owner.fullName
        },
        isSameDay(from, to) {
            return format(from, 'yyyyddM') === format(to, 'yyyyddM')
        },
        changeDate(booking) {
            if (booking.confirmedDate) {
                this.$parent.confirmBooking(booking)
            } else {
                if (!this.isSeller) {
                    this.$router.push({
                        name: 'EditBooking',
                        params: {
                            uuid: booking.uuid
                        }
                    })
                    return
                }
                this.showChangeDate =
                    this.showChangeDate !== booking.uuid ? booking.uuid : null
            }
            // this.bookings[index].confirmedDate = '2020-02-02'
        },
        reservationFrom(b) {
            return format(b.reservationFrom, 'EEEE do MMMM HH:mm')
        },
        reservationTo(b) {
            return format(b.reservationTo, 'EEEE do MMMM HH:mm')
        }
    }
}
</script>
