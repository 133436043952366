<template>
    <section class="">
        <div class="content">
            <h1 class="title">
                {{ date | formatDate('EEEE do MMMM', 'parse', 'yyyy-MM-dd') }}
            </h1>
            <p v-for="(booking, index) in bookings" :key="index">
                <a
                    :href="
                        'https://www.google.com/maps/dir/?api=1&destination=' +
                            encodeURIComponent(booking.fullAddress)
                    "
                    target="_blank"
                >
                    <b-icon icon="navigation" />
                </a>
                {{ booking.confirmedDate | formatDate('HH:mm') }}
                {{ booking.product.name }}<br />
                <span class="subtitle">{{ booking.fullAddress }}</span>
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MobileListView',
    props: { bookings: Array, date: String }
}
</script>
